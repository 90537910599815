<template>
  <CCard >
    <div class="card-body">
      <div class='form-inline'>
        <div class='form-group col-sm-12 col-lg-12'>
          <div class="float-right mr-1" v-if="is_measures">
            <select class='form-control' style='display:inline-block;height:32px' v-model='dateType'>
              <option v-for="dateType in dateTypes" :value="dateType.type" v-bind:key="dateType.type">{{dateType.name}} </option>
            </select>
          </div>

          <date-picker class="float-right mr-1" v-model="local_dates" type="date" range placeholder="Select date range"></date-picker>

          <CButton color="info" class="float-right mr-1" @click="searchStat()">조 회</CButton>
          <CButton color="success" class="float-right" @click="saveExcel()" :disabled="enable_excel == false">엑셀저장</CButton>
        </div>
      </div>
    </div>

  </CCard>
</template>

<script>
import moment from 'moment';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import utils from '@/services/utils';

import { DATE } from '@/constants/constant'

export default {
  name: 'Search',
  components: {
    // DatePicker
  },
  props: {
    dates: {
      type: Array,
      default: []
    },
    date_type: {
      type: Number,
      default: DATE.TYPE.RAWDATA
    },
    is_measures: {
      type: Boolean,
      default: false
    },
    enable_excel: {
      type: Boolean,
      default: false
    }
  },
  created: function() {
    this.local_dates = this.dates;
    this.dateTypes = [
      {
        type: DATE.TYPE.RAWDATA,
        format: DATE.FORMAT.RAWDATA,
        name: DATE.NAME.RAWDATA
      }, {
        type: DATE.TYPE.DAILY,
        format: DATE.FORMAT.DAILY,
        name: DATE.NAME.DAILY
      }, {
        type: DATE.TYPE.HOURLY,
        format: DATE.FORMAT.HOURLY,
        name: DATE.NAME.HOURLY
      }
    ]
  },
  data () {
    return {
      dateType: this.date_type,
      dateTypes: null,
      local_dates: null
    }
  },
  methods: {
    searchStat() {
      this.$emit('search', this.local_dates, this.dateType)
    },
    saveExcel() {
      this.$emit('save', {})
    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
</style>