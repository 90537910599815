<template>
    <div class="container">
        <CRow>
            <CCol col="12" xl="3" class="weather_img_col">
                <!-- <img src="{1}" alt="" style="width: 180px; height: 100px; border-radius: 10px; border: 2px solid gray;" />-->
                <img class="weather_img" :src="weather_image" alt="날씨">
            </CCol>
    
            <CCol col="12" xl="2" class="weather_contents">
                <div class="wrapper">
                    <CRow>
                        <!-- 온도 -->
                        <!-- <i class="fa-solid fa-temperature-three-quarters fa-2x" id="weather_icon"></i> -->
                        <i class="fa-solid fa-temperature-high fa-2x" id="weather_icon"></i>
                        <div class="data">{{ siteWeather.T1H }} ℃</div>
    
                    </CRow>
                </div>
                <div class="wrapper">
                    <CRow>
                        <!-- 습도 -->
                        <i class="fas fa-tint fa-2x" id="weather_icon" ></i>
                        <div class="data">{{ siteWeather.REH  }} %</div> 
                    </CRow>
                </div>
            </CCol>
            <CCol col="12" xl="2" class="weather_contents">
                <div class="wrapper">
                    <CRow>
                        <!-- 바람 -->
                        <i class="fas fa-wind fa-2x" id="weather_icon" ></i>
                        <div class="data">{{ siteWeather.WSD  }} m/s</div> 
                        </CRow>
                </div>
                <div class="wrapper">
                    <CRow>
                        <!-- 강수량 -->    
                        <i class="fas fa-umbrella fa-2x" id="weather_icon" ></i>
                        <div class="data">{{ siteWeather.RN1 }}mm</div> 
                    </CRow>
                </div>
            </CCol>
            <CCol col="12" xl="4" class="weather_contents">
                <div class="wrapper">
                    <CRow>
                        <!-- 위치 -->
                        <i class="fas fa-map-marker-alt fa-2x" id="location_icon"></i> 
                        <div class="data"></div><span id="location_addr">{{ address_ }}</span>
                    </CRow>
                </div>
                <div class="wrapper">
                    <CRow>
                        <!-- 시간 -->    
                        <div class="data" id="time_data"> {{ siteWeather.ncstTime}}(※ 기상청 데이터)</div>
                    </CRow>
                </div>
            </CCol>           
        </CRow>
    </div>  
    </template>

<script>
import Weather from '@/views/swfm/Weather'

export default {
name: 'PWDMWeather',
extends: Weather,
}
</script>


<style lang="scss" scoped>
.container {
    height: 110px;
    padding: 0.8%;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    transition: box-shadow .3s;
    justify-content: space-around;
    align-items: center;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
    border-radius: 14px;
    > * {
        display: flex;
        align-items: center;
    }
    .wrapper-tm {
        font-size: 13px;
        width: 130px;
    }
    .wrapper {
        width: 90%;
    }
    .data {
        margin-left: 5px;
    }
}

.weather_img{
    max-width: 45%;
    height: 60%;
    margin-left: 25%;
    margin-bottom: 5%;
}
.weather_contents{
    left: 2%;
    width: 60%;
}

#location_icon{
    color: #FF6969;
    
}
#location_data{
    font-size: 1em;
}

#location_addr{
    position: relative;
}

#weather_icon{
    color: #bbbbbb;
    width: 14%;
    padding: 1% 3% 1% 3%;
    margin-right: 11%;
    margin-bottom: 1.5%;
    font-size: 20px;
}

#time_data{
    color: grey;
    margin-left:4%;
    font-size: 0.9em;
    margin-top: 1.5%;
    text-align: center;
}
@media (max-width: 1800px) {
    #location_data{
        font-size: 0.8em;
    }
    #time_data{
        font-size: 0.7em;
    }
}

@media (max-width: 1200px) {
.container {
    height: 50px;
    display: none;
}
}
</style>