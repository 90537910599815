<template>
  <div class="container" id="newDeviceContainer">
    <!-- <ul class="nav nav-tabs">
      <li :class="{ 'active-tab': activeTab === '#firsttab' }" @click.prevent="changeTab('#firsttab')">
        <a href="#firsttab" data-toggle="tab"><i class="fa-solid fa-image" id="tab-icon"></i> 사진</a>
      </li>
      <li :class="{ 'active-tab': activeTab === '#secondtab' }" @click.prevent="changeTab('#secondtab')">
        <a href="#secondtab" data-toggle="tab"><i class="fa-solid fa-list" id="tab-icon"></i> 이력</a>
      </li>
      <div id="tab-name">디바이스명 : {{ device.name }}</div>
    </ul>

    <div v-else class="tab-content">
      <div class="tab-pane active" id="firsttab">
        <br />
        <HistoryImage :device.sync="device" />
      </div>
      <div class="tab-pane" id="secondtab">
        <HistoryManage :device.sync="device" :guid="guid" />
      </div>
    </div> -->

    <!-- tabs -->
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="pills-image-tab" data-toggle="pill" href="#pills-image" role="tab" aria-controls="pills-image" aria-selected="true"><i class="fa-solid fa-image" id="tab-icon"></i> 사진</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="pills-history-tab" data-toggle="pill" href="#pills-history" role="tab" aria-controls="pills-history" aria-selected="false"><i class="fa-solid fa-list" id="tab-icon"></i> 이력</a>
      </li>
      <div id="tab-name">디바이스명 : {{ device.name }}</div>
    </ul>
    
    <!-- content -->
    <LoadingSpinner v-if="isLoading" />
    <div v-else class="tab-content">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-image" role="tabpanel" aria-labelledby="pills-image-tab">
          <br />
          <HistoryImage :device.sync="device" />
        </div>
        <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab">
          <HistoryManage :device.sync="device" :guid="guid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';
import EventBus from '@/services/EventBus'


import EventLog from '@/views/srds/EventLog'
import HistoryImage from '@/views/pwdm/HistoryImage'
import HistoryManage from '@/views/pwdm/HistoryManage'
import LoadingSpinner from '@/views/pwdm/LoadingSpinner'


export default {
  name: "PWDMInfoTab",
  extends: EventLog,
  components: {
    HistoryManage,
    HistoryImage,
    LoadingSpinner
  },
  data() {
    return {
      guid: '',
      device: [],
      isLoading: true,
    }
  },
  mounted() {
    EventBus.$on("device", this.takeDeviceData)
  },
  beforeDestroy() {
    EventBus.$off("device")
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    takeDeviceData(data) {
        this.isLoading = false
        this.device = data
        this.guid = data.guid
        document.querySelector('#pills-image-tab').click();
    },
  }

}
</script>

<style scoped lang="scss">
#newDeviceContainer{
  justify-content: center; 
  padding: 1.4%;

}

#pills-tab {
  position: relative;
  border-bottom: 2px solid #d6d6d6;

  .nav-item {
    width: 100px;
    font-size: 16px;
  }

  #tab-name {
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    right: 1%;
    bottom: 20%;
  }
}

#first {
  display: flex;
}

#tab-icon{
  margin-right: 10%;
  margin-left: 5%;
}

.nav-tabs{
  border-bottom: 1px solid #eee;
  height: 45px;
}

.nav-tabs li {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 8px 10px 3px 8px;
  width: 85px;
  margin: 1.5px;
  cursor: pointer;

  a{
    text-decoration: none;
    color: #999;
  }
}

.nav-tabs li.active-tab {
  background-color: #039BE5;
  a {
    color: white;
    font-weight: 800;
  }
}

.nav-tabs li:not(.active-tab):hover {
  background-color: #039ae536;
}

.nav-tabs li:not(.active-tab):hover a {
  color: #039BE5
}

</style>