<template>
    <CRow>
        <CCol>
            <div class="table">
                <div class="table__header">
                    <div class="margin"></div>
                    <div class="state">방재상태</div>
                    <div class="desc">상세내용</div>
                    <div class="date">날짜</div>
                    <div class="settings" @click="openHistory('create')"><i class="fa-solid fa-pen-to-square"></i></div>
                </div>
                <div v-for="(row, index) in data" :key="index" class="table__contents">
                    <!-- <div class="margin"></div>  -->
                    <div class="btn"><input type="radio" :value="row" v-model="selected_row" /></div>
                    <div class="state">{{ row.state }}</div>
                    <div class="desc">{{ row.desc }}</div>
                    <div class="date">{{ row.updated_at }}</div>
                    <div class="settings">
                        <CButton color="info" :disabled="selected ? row != selected_row : false" @click="openHistory('modify')" style="width: 60px;">수정</CButton>
                        <CButton color="warning" :disabled="selected ? row != selected_row : false" @click="openHistory('delete')" style="width: 60px;">삭제</CButton>
                    </div>
                </div>
            </div>
        </CCol>

      <HistorySetting ref="HistorySetting" :device.sync="device" @hide="hideModal"/>

      <Confirm
          ref="confirmDialog"
          title="확인"
          @hide="hideModal"
          color="warning"
      />

      <Notify ref="notifyDialog"/>

    </CRow>
</template>

<script>
import moment from 'moment';
import utils from '@/services/utils';
import loopback from '@/services/loopback';

import HistoryDevice from '@/views/device/HistoryDevice'
import HistorySetting from '@/views/pwdm/HistorySetting'

export default {
    extends: HistoryDevice,
    components: {
        HistorySetting
    },
    props: {
        device: {
            type: Object,
            required: true
        },
        guid: {
            type: String,
        }
    },
    data() {
        var now = new Date()
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        var from = new Date(today.setTime(today.getTime() - 21 * 86400000)) // 3주전
        var to = new Date(now.setTime(now.getTime() + 86400000))
        return {
            dates: [from, to],
            selected_row: {},
            data: [],
            selected: true
        }
    },
    methods: {
        selectedRow(row) {
            this.selected_row = row
            console.log("selected_row : ", row)
        },
        dateFormatter(value) {
            return moment(value).format('YYYY-MM-DD')
        },
        hideModal(event) {
            if (event.name === 'HistorySettings' && event.which === 'create' || event.name === 'HistorySettings' && event.which === 'modify') {
                this.getHistory()
            } else
            if (event.name === 'Confirm' && event.which === 'delete') {
                if (event.data === 'confirm') {
                    this.deleteHistory();
                }
            }
        },
        openHistory(which) {
            let history = {
                updated_at: new Date(),
                user: this.user.realm,
                device_guid: this.device.guid,
                delete: false
            }
            switch(which) {
                case 'create':
                    this.$refs.HistorySetting.show(history)
                    break;
                case 'modify': 
                    history = _.cloneDeep(this.selected_row);
                    console.log("history row : ", history)
                    history.updated_at = new Date()
                    this.$refs.HistorySetting.show(history, 'modify')
                    break;
                case 'delete':
                        var message = "관리이력 : " + "테스트 삭제" + " 를 삭제 하시겠습니까?"
                        this.$refs.confirmDialog.show(message, '관리이력', undefined, 'delete');      
                    break;
                default:
                    break;
            }
        },
        getHistory() {
            var self = this;
            var filter = {
                where: {
                    and: [{
                            delete: false
                        },
                        {
                            updated_at: {
                                "gte": moment(this.dates[0]).utc().format('YYYY-MM-DD HH:mm:ss')
                            }
                        }, {
                            updated_at: {
                                "lte": moment(this.dates[1]).utc().format('YYYY-MM-DD HH:mm:ss')
                            }
                        },
                        {
                            device_guid: this.guid
                        },
                    ]
                },
                order: 'updated_at desc',
            }
            this.$store.dispatch('rest/find', {model:'device_histories',filter:filter})
                .then(res => {
                var rows = []
                res.forEach(e => {
                    rows.push({
                        updated_at: utils.dateFormatter(e.updated_at),
                        type: e.type,
                        files: e.files,
                        user: e.user,
                        desc: e.desc,
                        state: e.state,
                        guid: e.guid
                    })
                })
                self.data = rows;
                if (rows.length > 0) {
                    setTimeout(function() {
                        $('#historyTable').bootstrapTable('check', 0)
                    }, 100)
                }
                })
                .catch(err => {
                    console.log('History::getHistory Error: ', err.toString())
                })
        },
        deleteHistory() {
            loopback
            .upsertWithWhere('device_histories', {guid: this.selected_row.guid}, {delete: true})
            .then(res => {
                if (_.isUndefined(this.selected_row.files)) {
                    return res;
                } else {
                    return loopback
                        .deleteById('histories', this.selected_row.guid)
                }
            })
            .then(res => {
                this.selected_row = null;
                this.getHistory();
            })
            .catch(err => {
                console.log('History::delete err:', err.toString())
            })
        },
    },
    watch: {
        device: {
            deep: true,
            handler(newVal, oldVal) {
                this.getHistory()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.table {
    height: 320px;
    overflow-y: scroll;
    .table__header {
        display: flex;
        border-bottom: 1px solid gray;
        margin-bottom: 1.4%;
        height: 58px;
        text-align: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        .margin {
            width: 5%;
        }
        .state {
            width: 20%;
        }
        .desc {
            width: 40%;
        }
        .date {
            width: 15%;
        }
        .settings {
            cursor: pointer;
            width: 20%;
        }
    }
    .table__contents {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 16px;
        height: 52px;
        margin-bottom: 1.6%;
        &:hover {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(33,33,33,.2);
        }
        .margin {
        }
        .btn {
            width: 5%;

        }
        .state {
            width: 20%;
        }
        .desc {
            width: 40%;
        }
        .date {
            width: 15%;
        }
        .settings {
            width: 20%;
        }
    }
}


</style>