<template>
  <div class="spinner-container">
    <div class="spinner" />
    <div class="title">Device를 선택해주세요</div>
  </div>
</template>
  
<script>
export default {};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #e0e0e0;
  border-bottom: 5px solid #039BE5;
  animation: spin 1s linear infinite;
  position: relative;
}
.title {
  position: absolute;
  top: 54%;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
