<template>
    <KModal
      :show.sync="showModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Create Device Dialog"
      color="info"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title text-center">이력관리{{ title }}</h6>
      </template>
  
      <div class='modal-body'>
        <form>
          <CRow>
            <CCol col="12" xl="6">
              <label>장치명</label>
              <select id="upload_cron" class='form-control' v-model="device.name">
                <option>{{ device.name }}</option>
              </select>
            </CCol>
            <CCol col="12" xl="6">
              <label>날짜</label>
              <date-picker v-model="history.updated_at" type="datetime" format="YYYY-MM-DD HH:mm:ss" style="width:100%;"></date-picker>
            </CCol>
            <CCol col="12" xl="6" style="margin-top: 2%;">
              <label>방재상태</label>
              <select id = "upload_cron" class='form-control' v-model="history.state">
                <option v-for="(state, index) in state" :value="state" :key="index">{{ state }} </option>
              </select>
            </CCol>
            <CCol col="12" xl="6" style="margin-top: 2%;">
              <label>관리자</label>
              <input class='form-control' style="width:100%;" v-model="history.user" />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol col="12" xl="12">
              <label>상세내용</label>
              <textarea v-model="history.desc" placeholder=" 상세내용" style="width:100%; height:120px;"></textarea>
            </CCol>
          </CRow>
        </form>
      </div>

      <template #footer>
        <CButton @click="startUpload()" color="info">업로드</CButton>
        <CButton @click="cancel()" color="light">취소</CButton>
      </template>
  
      <Notify ref="notifyDialog"/>
  
    </KModal>
</template>
  
<script>
import moment from 'moment';
import loopback from '@/services/loopback';

export default {
  name: 'HistorySettings',
  props: {
    device: {
      type: Object
    }
  },
  data() {
    return {
      title: '',
      history: {},
      which: '',
      selectedStatus: '',
      state: [
        '선별 대기중', '방재 대기중', '파쇄 완료', '벌채 완료', '방재 완료'
      ],
      showModal: false,
    }
  },
  methods: {
    show(history, which='create') {
      this.history = history;
      this.which = which;
      this.title = which === 'create' ? '생성' : '변경';
      if (this.which === 'create') {
        this.showModal = true
        return;
      }
      this.showModal = true
    },
    startUpload() {
      // this.history.updated_at = moment(this.history.updated_at).utc().format('YYYY-MM-DD HH:mm:ss')
      // console.log("this.history.updated_at : ", this.history.updated_at)
      if (this.which === 'create') {
        loopback
          .upsert('device_histories', this.history)
          .then(res => {
            this.history = res;
            this.confirm();
          })
          .catch(err => {
            console.log('HistoryUploader::startUpload error:', err.toString())
          })        
      } else {
        loopback
          .upsertWithWhere('device_histories', {guid: this.history.guid}, this.history)
          .then(res => {
              this.confirm();
          })
          .catch(res => {
            console.log('HistoryUploader::startUpload error:', err.toString())
          })        
      }
    },
    confirm() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'confirm'
      }
      this.$emit('hide', msg);
      this.showModal = false
    },
    cancel() {
        this.showModal = false
    }
  },
  // watch: {
  //   device(newVal, oldVal) {
  //   }
  // }
}
</script>
  
<style>
  
</style>