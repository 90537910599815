<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Editor Dialog"
    :color="color"
  >
    <!-- size="lg" -->

    <template #header>
      <h6 class="modal-title text-center">{{title}} : {{editFile.name}}</h6>
    </template>

    <!-- <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    /> -->

    <div class="modal-body">
      <div class="row" style="width:100%">
        <vue-cropper
          ref="cropper"
          :aspect-ratio="16 / 9"
          :src="editFile.name"
          preview=".preview"
        />
      </div>

      <div class="search-margin"></div>

      <div class="row">
        <div class='col-sm-12 col-lg-12'>
          <CButton color="primary" @click="rotate(-90)" title="rotate(-90)"><FontAwesomeIcon :icon="['fa', 'undo-alt']" aria-hidden="true"/></CButton>
          <CButton class="m-1" color="primary" @click="rotate(90)" title="rotate(90)"><FontAwesomeIcon :icon="['fa', 'sync-alt']" aria-hidden="true"/></CButton>
          <CButton class="float-right" @click="cancel()" color="light">취소</CButton>
          <CButton class="float-right mr-1" @click="save()" color="info">저장</CButton>
        </div>
      </div>
    </div>

    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>

  </KModal>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
  name: 'Editor',
  components: {
    VueCropper
  },
  props: {
    color: {
      type: String,
      default: 'info'
    },
  },
  data() {
    return {
      showModal: false,
      title: '이미지 파일 편집',
      which: 'confirm',
      button: '확인',
      editFile: {
        show: false,
        name: '',
      },
      cropImg: '',
      data: null,      
    }
  },
  watch: {
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    cropImageFile() {
      let data = {
        name: this.editFile.name,
        error: '',
      }

      let binStr = atob(this.$refs.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      data.file = new File([arr], data.name, { type: this.editFile.type })
      data.size = data.file.size
      return data
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          // this.editFile.name = file.name
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    setImageFile(file) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },    
    save() {
      let msg = {
        name: this.$options.name,
        which: 'save',
        data: this.cropImageFile()
      }
      this.$emit('save', msg);
      this.showModal = false;
    },
    cancel() {
      this.editFile.show = false      
      this.showModal = false;
    },
    show(file) {
      this.editFile = file
      this.setImageFile(file.file)
      this.showModal = true;
    }
  }
}
</script>

<style>
.search-margin {
  margin: 10px 0 10px;
}

.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

</style>
