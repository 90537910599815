<template>
  <div class="containerBox">
    <div class="searchBar">
      <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
      <select id="select_id" class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">장소를 선택하세요</option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
      </select> 
    </div>

    <div class="searchResult">
      <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" v-for="(data, index) in site.data" :key="index" @click="sendDeviceData(data)">
          <div class="title" id="psdm-title"><router-link :to="`srds_device/${data.guid}`">{{ data.name }}</router-link></div>
          <div class="underline"></div>
          <div class="imgWrapper">
            <div class="img" v-html="findCameraImg(data)"></div>
            <div class="rfstatus" v-html="findRFImg(data)"></div>
            <div class="battery" v-html="findBatteryImg(data)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Device from '@/views/srds/Device'
import utils from '@/services/utils';
import EventBus from '@/services/EventBus'

export default {
    name: 'PWDMDevice',
    extends: Device,
    // mounted(){
    //     this.selected = this.user.active_site_index
    //     this.onClickTab(this.active_site_index)
    // },
    methods: {
      sendDeviceData(data) {
        EventBus.$emit('device', data)
      },
      onClickTab(index) {
        var active_site_index = _.get(this.user, "active_site_index", -1);
        if (active_site_index != index) {
          this.user.active_site_index = index;
          this.user.active_site_name = this.siteTables[index].title;
          utils.setStorage("userInfo", this.userInfo);
        }
        return this.getDeviceList(index)
          .then((siteTable) => {
            let data = {
              site_index : index,
              site : siteTable.title
            };
            EventBus.$emit("send", data)
            this.sendDeviceData(siteTable.data[0])
            return Promise.resolve(siteTable)
          .then(()=>{
              if (!this.$parent.$refs.eventView) return null;
              var eventTable = this.$parent.$refs.eventView.getTable();
              eventTable.data.forEach((event) => {
                this.$parent.$refs.deviceView.setMarkerImage(event);
              });
            })
          })
          .catch(err => {
            // ignore
          })
      },
      findRFImg(data) {
        var result = "";
        var status_text = "";
        var imgsrc;
        if (new Date(data.prev_time).getTime() <= new Date(data.sensor_time).getTime()) {
          imgsrc = `<i class="fa-solid fa-wifi" style="color:#43a047;"></i>`
          return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: green; font-weight: bold;'>${status_text}</span>`;
        }
        else {
          imgsrc = `<i class="ri-wifi-off-fill" style="color:rgb(230, 81, 0);"></i>`
          return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: rgb(230, 81, 0); font-weight: bold;'>${status_text}</span>`;
        }
      },
      findBatteryImg(data) {
        var result = "";
        // var status_text = "";
        var imgsrc;
        var thresholds = utils.getThresholds();
        try {
          var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
          var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
          var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
          var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
        } catch(err) {
          return;
        }
        if (_.isUndefined(data.sensor)){
            console.log("isUndefined");
            return;
        }

        data.sensor.data.forEach(function(e){
          if(e.type === 1)
            result = e.value;
        })

        if (result >= level0){ 
          //console.log("level0");
          imgsrc = `<i class="fa fa-battery-full fa-lg" style="color:#43a047;"></i>`
          // status_text = IMAGES.BG.status;
        }
        else if (result >= level1 ){ 
          //console.log("level1");
          imgsrc = `<i class="fa fa-battery-three-quarters fa-lg" style="color:#fbc02d;"></i>`
          // status_text = IMAGES.BN.status;
        }
        else if (result >= level2 ){
          //console.log("level2");                
          imgsrc = `<i class="fa fa-battery-half fa-lg" style="color:#fbc02d;"></i>`
          // status_text = IMAGES.BC.status;
        }
        else if (result >= level3 ){
          //console.log("level3");
          imgsrc = `<i class="fa fa-battery-quarter fa-lg" style="color:#e65100;"></i>`
    
        }
        else {
          //console.log("교체필요");              
          return `<div class="containerBox"><div style="margin-left: 4px;"><i class="fa fa-battery-quarter fa-lg" style="color:#e65100; margin-left: 15px;"></i></div></div>`  /*  <div><span style ='color: #e65100; font-weight: bold;'> 교체필요 </span></div> */
        }
        return `<div class="containerBox"><div style="margin-left: 4px;">${imgsrc}</div></div>` /* <div>${status_text}</div> */
      },
    }
}
</script>
<style dashdevice>

/* here */
.searchBar {
  display: flex;
  margin: 10px 15px 10px;
  width: 93%;

}

.searchBar .form-control {
  margin-left: 10px;
  margin-right: 10px;
}

.searchBar > select {
  padding-left: 2%;
  font-weight: 800;
  width: 30%;
}

.searchBar .selects select {
  width: 160px;
  height: 35px;
  margin-right: 10px;
}
.searchResult {
  height: 158px;
  overflow: auto;
  overflow-x: none;
}

.group {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.group-item {
  background: #f0f0f099;
  border : 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  width: 190px;
  height: 140px;
  margin-top: 4px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  transition: box-shadow .3s;
  justify-content: center;
  align-items: center;
}

.group-item:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.title {
  font-size: 18px;
  font-weight: bold;
}

#psdm-title{
  text-align: center;
  border-radius:7px;
}

.title > a {
  text-decoration: none; 
  color: black;

}

.underline {
  width: 170px;
  border-top: 1px dotted rgb(179, 179, 179);
  margin-bottom: 2%;
}

.iconWrapper {
  display: flex;
}

.level {
  margin-left: 10px;
}

.battery {
  position: absolute;
  top: 3px;
  right: 7px;
  z-index: 1; /* 배터리 아이콘을 이미지 위에 표시 */
}

.rfstatus {
  position: absolute;
  top: 1.8px;
  right: 32px;
  z-index: 1;

}

.imgWrapper {
  cursor: pointer;
  position: relative;
}

.sensor_time {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  width: 115px;
  background-color: rgba(0,0,0,.7);
  margin: 0 auto;
  
}
.sensor_time > p{
  margin-bottom: -5px;
}

.searchResult::-webkit-scrollbar {
  background-color: #fff;
  margin-right: 10px;
  width: 7px !important;
  height: 8px;
}
.searchResult::-webkit-scrollbar-track {
  background-color: #fff;
}
.searchResult::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4xp solid #fff;
}

.ri-wifi-off-fill{
  color: rgb(230, 81, 0);
  /* font-weight: 900; */
  font-size: 1.25em;
  line-height: .05em;
  vertical-align: -0.075em;
}
@media (max-width: 414px) {
  .group {
    justify-content: center;
    align-items: center;
  }
}
</style>