<template>
  <div class="container">
      <CRow>
          <CCol col="12" xl="6" class="weather_img_col">
              <!-- <img src="{1}" alt="" style="width: 180px; height: 100px; border-radius: 10px; border: 2px solid gray;" />-->
              <img class="weather_img" :src="weather_image" alt="날씨">
          </CCol>
  
          <CCol col="12" xl="6" class="weather_contents">
              <div class="wrapper">
                  <CRow>
                      <!-- 온도 -->
                      <!-- <i class="fa-solid fa-temperature-three-quarters fa-2x" id="weather_icon"></i> -->
                      <i class="fa-solid fa-temperature-high fa-2x" id="weather_icon"></i>
                      <div class="data">{{ siteWeather.T1H }} ℃</div>
  
                  </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 습도 -->
                      <i class="fas fa-tint fa-2x" id="weather_icon" ></i>
                      <div class="data">{{ siteWeather.REH  }} %</div> 
                  </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 바람 -->
                      <i class="fas fa-wind fa-2x" id="weather_icon" ></i>
                      <div class="data">{{ siteWeather.WSD  }} m/s</div> 
                      </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 강수량 -->    
                      <i class="fas fa-umbrella fa-2x" id="weather_icon" ></i>
                      <div class="data">{{ siteWeather.RN1 }}mm</div> 
                  </CRow>
              </div>
          </CCol>       
      </CRow>
      <CCol col="12" xl="12" class="location_contents" >
          <div class="data" id="location_data"> <i class="fas fa-map-marker-alt fa-2x" id="location_icon"></i> <span id="location_addr">{{ address_ }}</span></div>
          <div class="wrapper">
          <div class="data" id="time_data"> {{ siteWeather.ncstTime}}(※ 기상청 데이터)</div>
      </div>
      </CCol>
  
  </div>  
  </template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

export default {
  name: 'Weather',
  components: {
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites

    this.active_site_index = _.get(this.user, "active_site_index", 0);
    //this.active_site_name = this.user.active_site_name;

    this.getWeatherData(this.active_site_index, this.user.active_site_name);
  },
  data () {
    return {
      active_site_index: 0,
      siteWeather: {},
      weather_image: 'images/cloud.png',
      address_ : ""
    }
  },
  methods: {
    getWeatherData(index, address) {
      var self = this;
      var default_site_guid = _.get(this.user, "default_site_guid");
      this.address_ = address;
      var data = {
        from: moment(new Date().setMinutes(0, 0, 0)).subtract(1,'day'),
        to: moment(new Date().setMinutes(0, 0, 0)).add(9,'hour'),
        site_guid: _.isEmpty(this.user.active_site_guids[index]) ? default_site_guid : this.user.active_site_guids[index]        
      }
      return loopback
        .method('weather', 'getWeatherStat', data)
        .then(res => {
          if(res.data.length == 0){
            console.log("load default data none");
          }
          else{
            res.data[0].VEC = utils.getWindDirectionName(Number(res.data[0].VEC))
            self.siteWeather = res.data[0];
            self.siteWeather.ncstTime = self.parseFcstTime(self.siteWeather.ncstTime);
            self.weather_image = this.getWeatherImage(self.siteWeather.PTY);
          }
          return Promise.resolve(self.siteWeather)
        })
    }, 
    parseFcstTime(time) {
      if(!/^(\d){12}$/.test(time)) return "Invalid Date";
      var dt = time.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)$/, '$1-$2-$3 $4:$5');
      return moment(new Date(dt)).format("MM월 DD일 HH시 예보");
    },
    getWeatherImage(pty) {
      var image_ = 'images/cloud.png';
      switch(pty){
        case '0':
          image_ = 'images/sun.png';
          break;
        case '1':
          image_ = 'images/rainy.png';
          break;
        case '2':
          image_ = 'images/cloudy_rain.png';
          break;
        case '3':
          image_ = 'images/snowy.png';
          break;
        case '4':
          image_ = 'images/rainy.png';
          break;
        case '5':
          image_ = 'images/hail.png';
          break;
        case '6':
          image_ = 'images/snowy.png';
          break;
        case '7':
          image_ = 'images/snowy.png';
          break;                                          
      }      
      return image_;
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
    height: 220px;
    padding: 2%;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    transition: box-shadow .3s;
    justify-content: space-around;
    align-items: center;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
    border-radius: 14px;
    > * {
        display: flex;
        align-items: center;
    }
    .wrapper-tm {
        font-size: 13px;
        width: 130px;
    }
    .wrapper {
        width: 90%;
    }
    .data {
        margin-left: 5px;
    }
}

.weather_img{
    max-width: 65%;
    height: 70%;
    margin-left: 25%;
}
.weather_img_col {
    border-right: 1px solid #ccc;
}

.weather_contents{
    left: 3%;
    width: 40%;
}
.location_contents{
    align-items: baseline;
    display: contents;
}

#location_icon{
    color: #FF6969;
    
}
#location_data{
    font-size: 1em;
}

#location_addr{
    position: relative;
    top:-0.4vh;
}

#weather_icon{
    color: #bbbbbb;
    width: 14%;
    padding: 1% 3% 1% 3%;
    margin-right: 11%;
    margin-bottom: 1.5%;
    font-size: 20px;
}

#time_data{
    color: grey;
    margin-left:4%;
    font-size: 0.9em;
    text-align: center;
}
@media (max-width: 1800px) {
    #location_data{
        font-size: 0.8em;
    }
    #time_data{
        font-size: 0.7em;
    }
}

@media (max-width: 1200px) {
.container {
    height: 50px;
    display: none;
}
}
</style>