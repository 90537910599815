<template>
  <div>
    <CCard style="height: 155px">
      <Summation ref="summationView" @click="onClick" />
    </CCard>

    <CCard>
      <Device ref="deviceView" @change="onChangedSite" />
    </CCard>

    <Event ref="eventView" @update="updateSummation" @click="onClickEvent" />

    <EventDialog ref="eventDialog" @click="onClickEvent"/>
  </div>
</template>

<script>
import Summation from "@/views/dashboard/Summation";
import Device from "@/views/dashboard/Device";
import EventDialog from "@/views/dashboard/EventDialog";
import Event from "@/views/device/Event";
import utils from "@/services/utils";

export default {
  name: "Dashboard",
  components: {
    Summation,
    Device,
    EventDialog,
    Event,
  },
  created: function () {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites;

    var userInfo = utils.getStorage("userInfo");
  },
  data() {
    return {
      is_admin: this.$store.state.auth.is_admin,
      selected_row: null,
      selected_site_devices: [],
    };
  },
  methods: {
    onClick(item) {
      // console.log('Dashboard::onClick Summation click item:', JSON.stringify(item,null,2))
      var eventTable = this.$refs.eventView.getTable();
      this.$refs.eventDialog.show(item, eventTable.data);
    },
    onClickEvent(field, value, row) {
      this.$refs.deviceView.onEvent(field);
    },
    onChangedSite(siteTable) {
      // console.log('Dashboard::onChangedSite siteTable:', siteTable)
      this.selected_site_devices = siteTable;
      var eventTable = this.$refs.eventView.getTable();
      eventTable.data.forEach((event) => {
        // console.log('Dashboard::onChangedSite setMakerImage(event):', event)
        this.$refs.deviceView.setMarkerImage(event);
      });
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          }
        })
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        });
    },
  },
};
</script>
