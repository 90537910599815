<template>
  <CRow>
    <CCol col="12" xl="12">
      <Search 
          :dates.sync="dates" 
          :is_measures="false" 
          :enable_excel.sync="enable_excel"
          @search="searchHistory"
          @save="saveHistory()"
      />

      <CCard>
        <CCardBody col="12" xl="12">
          <v-table id="historyTable" :columns="columns" :data="data" :options="options"></v-table>
        </CCardBody>
        <CCardFooter col="12" xl="12">
          <CButton color="warning" class="float-right" @click='openHistory("delete")' :disabled='isAdmin == false'>삭제</CButton>
          <CButton color="info" class="float-right mr-1" @click='openHistory("update")' :disabled="enable_excel == false">수정</CButton>
          <CButton color="success" class="float-right mr-1" @click='openHistory("create")'>생성</CButton>
        </CCardFooter>
      </CCard>

      <HistoryUploader
          id="historyUploader"
          ref="uploaderDialog"
          :device_guid="device_guid"
          storage="histories"
          @hide="hideModal"
      />

      <Confirm
          ref="confirmDialog"
          title="확인"
          @hide="hideModal"
          color="warning"
      />

      <Progress
          ref="progressDialog"
          title="확인"
      />

      <Notify ref="notifyDialog"/>

    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import XLSX from 'xlsx';
import utils from '@/services/utils';

import EventBus from '@/services/EventBus'
import Search from '@/views/device/Search';

import { DATE } from '@/constants/constant'
import {
  HISTORY_TABLE_OPTIOINS, HISTORY_TABLE_COLUMS
} from "@/constants/historySettings";

import Progress from '@/views/popups/Progress';
import HistoryUploader from '@/views/device/HistoryUploader';

export default {
  name: 'HistoryDevice',
  components: {
    Search,
    Progress,
    HistoryUploader
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;

    _.find(this.columns, { field: 'updated_at' }).formatter = utils.dateFormatter;
    utils.loadThresholds(res => this.thresholds = res)
  },
  mounted() {
    var self = this;
    $('#historyTable').on('check.bs.table', function(e, row, $element) {
      self.selected_row = row;
    });
  },
  data () {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var from = new Date(today.setTime(today.getTime() - 7 * 86400000))
    var to = new Date(now.setTime(now.getTime() + 86400000))

    // from = new Date('2019-01-01'); // test

    return {
      isAdmin: this.$store.state.auth.is_admin,
      date_type: DATE.TYPE.RAWDATA,
      dates: [from, to],
      enable_excel: false,
      options: HISTORY_TABLE_OPTIOINS,
      columns: HISTORY_TABLE_COLUMS,
      data : [],
      selected_row: null,
      thresholds: []
    }
  },
  methods: {
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      return this.dates;
    },   
    hideModal(event) {
      console.log('History::hideModal event:', event)
      if (event.name === 'HistoryUploader' && event.which === 'create') {
        this.getHistory()
      } else
      if (event.name === 'Confirm' && event.which === 'delete') {
        if (event.data === 'confirm') {
          this.deleteHistory();
        }
      }
    },
    deleteHistory() {
      loopback
        .upsertWithWhere('device_histories', {guid: this.selected_row.guid}, {delete: true})
        .then(res => {
          console.log('History::delete info:', res)
          if (_.isUndefined(this.selected_row.files)) {
            return res;
          } else {
            return loopback
              .deleteById('histories', this.selected_row.guid)
          }
        })
        .then(res => {
          this.selected_row = null;
          this.getHistory();
        })
        .catch(err => {
          console.log('History::delete err:', err.toString())
        })
    },
    openHistory(which) {
      switch (which) {
        case 'create':
          var history = {
            device_guid: this.device_guid,
            updated_at: new Date(),
            user: this.user.realm + "/" + this.user.email,
            delete: false
          }
          this.$refs.uploaderDialog.show(history);
          break;
        case 'update':
          var history = _.cloneDeep(this.selected_row);
          delete history.state;
          history.updated_at = new Date(history.updated_at)
          this.$refs.uploaderDialog.show(history, 'update');
          break;
        case 'delete':
          var message = "관리이력 : " + this.selected_row.type + " 를 삭제 하시겠습니까?"
          this.$refs.confirmDialog.show(message, '관리이력', undefined, 'delete');      
          break;
        default:
          break;
      }
    },
    searchHistory(dates) {
      this.dates = dates;
      this.getHistory();
    },
    makeFileName(name) {
      var from = moment(this.dates[0]).format('YYYY-MM-DD');
      var to = moment(this.dates[1]).format('YYYY-MM-DD');
      return name + "_" + from + "-" + to;
    },
    saveHistory() {
      var self = this;
      if (_.isEmpty(this.data)) {
        this.$refs.notifyDialog.show('검색된 데이터가 없습니다.');
        return;
      }

      var write = function(callback) {
        var historyData = self.data;
        var r = [];
        var wscols = [
          { wch: 20 },
          { wch: 15 },
          { wch: 50 },
          { wch: 25 }
        ];

        historyData.forEach(function(data, i) {
          var row = {
            '조치일자': moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss'),
            '타입': data.type,
            '조치내용': data.desc,
            '등록자': data.user
          }
          r.push(row);
        })

        var ws = XLSX.utils.json_to_sheet(r);
        ws['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, self.device.name);
        var filename = self.makeFileName('고장이력');
        XLSX.writeFile(wb, filename + ".xlsx");
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    },
    getHistory() {
      var self = this;
      var filter = {
        where: {
          and: [{
              delete: false
            },
            {
              updated_at: {
                "gte": moment(this.dates[0]).utc().format('YYYY-MM-DD HH:mm:ss')
              }
            }, {
              updated_at: {
                "lte": moment(this.dates[1]).utc().format('YYYY-MM-DD HH:mm:ss')
              }
            },
            {
              device_guid: this.device_guid
            },
          ]
        }
      }
      this.enable_excel = false;
      this.$store.dispatch('rest/find', {model:'device_histories',filter:filter})
        .then(res => {
          var rows = []
          res.forEach(e => {
            rows.push({
              updated_at: e.updated_at,
              type: e.type,
              files: e.files,
              user: e.user,
              desc: e.desc,
              guid: e.guid
            })
          })
          self.data = rows;
          self.enable_excel = !_.isEmpty(rows);
          if (rows.length > 0) {
            setTimeout(function() {
              $('#historyTable').bootstrapTable('check', 0)
            }, 100)
          }
        })
        .catch(err => {
          console.log('History::getHistory Error: ', err.toString())
        })
    },
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
</style>