<template>
    <div class="container" style="display: flex;">
        <template v-if="deviceImages[0]">
            <div class="imgWrapper">
                <div class="img-box">
                    <img class="pwdm-img" :src="deviceImages[1].url" width="100%" height="320px" />
                    <br />
                    <p class="date" id="date1"> {{ deviceImages[1].created_at }} <br /> {{ deviceImages[1].created_at_time }}</p> 
                    <!-- <span id="prev-date"> ( {{ time_ago }} 전 )</span> -->
                </div>
                <i class="fa-solid fa-angles-right fa-fade fa-2xl"></i>
                <div class="img-box">
                    <img class="pwdm-img" :src="deviceImages[0].url" width="100%" height="320px" />
                    <br />
                    <p class="date" id="date2">{{ deviceImages[0].created_at }} <br /> {{ deviceImages[0].created_at_time }}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';

export default {
    props: {
        device: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            deviceImages: [],
            device_guid: this.device.guid,
            selectedDevice: this.device,
            storage: "flowPhotos",
            time_ago: '1시간',
        }
    },
    created() {
        this.getDeviceImages(this.device_guid)
    },
    watch: {
        device: {
            deep: true,
            handler(newVal, oldVal) {
                //console.log("newval : ", newVal)
                this.device_guid = newVal.guid
                this.selectedDevice = newVal
                this.getDeviceImages(this.device_guid)
            }
        }
    },
    methods: {
        setTime() {
            var later = this.deviceImages[1].created_at
            var previous = this.deviceImages[0].created_at
            if(later === previous) {
                console.log(later === previous)
            } else {
                if(this.deviceImages[1].created_at_total > this.deviceImages[0].created_at_total) {
                    var test1 = new Date(this.deviceImages[1].created_at_total)
                    var test2 = new Date(this.deviceImages[0].created_at_total)
                    console.log("test1 : ", test1)
                    console.log("test2 : ", test2)
                }
            }
        },
        getDeviceImages(guid) {
            var self = this;
            this.deviceImages = [];
            var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, guid);
                var filter = {
                where: {
                    and: [{
                        device_guid: self.device_guid
                    },
                    ]
                },
                order: 'created_at desc',
                limit: 2,
                }

                self.$store.dispatch('rest/find', {model:'photos',filter:filter})
                    .then(function(response) {
                    var pushImage = function(info) {
                        self.deviceImages.push({
                            url: download_url + '/' + info.file_name,
                            created_at_total: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초'),
                            created_at: moment(info.created_at).format('YYYY년 MM월 DD일'),
                            created_at_thumb: moment(info.created_at).format('YYYY-MM-DD'),
                            created_at_time: moment(info.created_at).format('HH시 mm분 ss초')
                        })
                    }
                    for (var i = 0; i < response.length; i++) {
                        var info = response[i]
                        if (_.isUndefined(info.storage)) continue;
                        pushImage(info);
                    }
                    if (self.deviceImages.length == 0) {
                        console.log("self.device : ", self.device)
                        var network_type = self.device.network_type;
                        console.log("self.device.network_type : ", self.device.network_type)
                        download_url = 'img/tech9_dev.png'; 
                        switch (network_type.toLowerCase()) {
                            case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
                            case 'lora': download_url = 'img/tech9_lora.jpg'; break;
                        }
                        self.deviceImages.push({
                            url: download_url
                        })
                    }
                    
                })
        }
    }
}
</script>

<style scoped lang="scss">
.container {
  .imgWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa-angles-right {
        font-size: 42px;
    }
    .img-box {
      position: relative;
      margin: 1%;
      .pwdm-img{
        border-radius: 3px;
      }
      .date {
        width: 90%;
        height: 15%;
        position: absolute;
        bottom: 0px;
        left: 5%;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        align-items: center;
        align-content: center;
        background-color: rgba(76,76,76,0.7);
        border-radius: 3px;
        color: white;
        padding-top:0.8%;
        min-height: 45px;
        
      }
    }
  }
  #date2{
      background-color:#039ae5ca;
      font-weight: 800;    
  }
  #prev-date{
    font-weight: 800;
    color: #039BE5;
  }

}
</style>