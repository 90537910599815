<script>
import EventDialog from '@/views/dashboard/EventDialog'

export default {
  name: 'EventDialogExt',
  extends: EventDialog,
  mounted() {
    _.find(this.columns, { field: 'sensor_type' }).visible = false;
  },
}
</script>