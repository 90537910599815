const HISTORY_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: true,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'updated_at',
    sortOrder: 'desc',
    uniqueId: 'guid',
}

const HISTORY_TABLE_COLUMS = [
    {
        field: 'state',
        radio: true
    }, {
        field: 'updated_at',
        title: '조치일자',
        align: 'center',
        valign: 'top',
        sortable: true,
    }, {
        field: 'type',
        title: '타입',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'files',
        title: '데이터',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'desc',
        title: '조치내용(상세)',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'user',
        title: '등록자',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

export {
    HISTORY_TABLE_OPTIOINS,
    HISTORY_TABLE_COLUMS
}