<script>
import GoogleMap from '@/views/dashboard/GoogleMap'
import utils from '@/services/utils'

export default {
  name: 'GoogleMapExt',
  extends: GoogleMap,
  methods: {
    buildContents(data, sensor_type) {
      var content = "";
      if (data && Array.isArray(data)) {
        var item = "";
        var type, id;
        for (var i = 0; i < data.length; i++) {
          type = data[i].type;
          id = data[i].id;
          if(type == 8){if(id > 3) break;}
          if (type == sensor_type) {
            item = utils.getSensorTypeName(type, id, false);
            content += '<tr><td>' + item.replace(" 각도","") + ": " + data[i].value + '</td></tr>';          }
        }
      }
      return content;
    },
  }
};
</script>